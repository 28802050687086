import React                            from 'react';
import { graphql, useStaticQuery }      from 'gatsby';
import { FormattedDate, FormattedTime } from 'react-intl';

import Wrapper   from '../components/structure/Wrapper/Wrapper';
import Heading   from '../components/text/Heading/Heading';
import Spacer    from '../components/structure/Spacer/Spacer';
import { findT } from '../components/_helpers';

const InternessInfoPage = () => {
  const metaData = useStaticQuery(query);
  return (
    <>
      <Spacer/>
      <Wrapper>
        <Heading>Info</Heading>
        <h2>Letzte Aktualisierung:</h2>
        <p>Datum des letzten technischen oder Inhaltlichen Updates der Webseite.</p>
        <p>
          <span><FormattedDate value={metaData.siteBuildMetadata.buildTime}
                               hour12={false}
                               year='numeric'
                               month='long'
                               day='2-digit'/></span>
          <span> <FormattedTime value={metaData.siteBuildMetadata.buildTime}/> Uhr</span>
        </p>
        <Spacer/>
        {metaData.brands.group.length > 0 && <>
          <h2>Marken (letzte Aktualisierung)</h2>
          {metaData.brands.group.map(group => {
            return <div key={group.fieldValue}>
              <h4>{findT(metaData.brandTypes.nodes.find(brandType => brandType.type === group.fieldValue).translations, 'de').display_name}</h4>
              <ul>
                {group.nodes.map(({ brand }) =>
                  <li key={brand.display_name}>
                    {brand.display_name} -
                    <FormattedDate value={new Date(Date.parse(brand.modified_on))}
                                   hour12={false}
                                   year='numeric'
                                   month='long'
                                   day='2-digit'/>
                  </li>
                )}
              </ul>
            </div>;
          })}
        </>
        }
        <Spacer/>
        <h2>Plugins</h2>
        <ul>
          {metaData.allSitePlugin.nodes.map(plugin => <li key={plugin.name}>{plugin.name} ({plugin.version})</li>)}
        </ul>
        <Spacer/>
        <h2>Umgebungsvariablen</h2>
        <ul>
          <li>API_ENDPOINT: {process.env.GATSBY_API_ENDPOINT}</li>
          {process.env.GATSBY_ORDER_API_ENDPOINT && <li>ORDER_API_ENDPOINT: {process.env.GATSBY_ORDER_API_ENDPOINT}</li>}
          {process.env.GATSBY_API_PROJECT && <li>GATSBY_API_PROJECT: {process.env.GATSBY_API_PROJECT}</li>}
        </ul>
      </Wrapper>
    </>
  )
}

export default InternessInfoPage;

const query = graphql`
  query {
    siteBuildMetadata {
      buildTime
    }
    allSitePlugin(filter: {name: {regex: "/@interness/"}}) {
      nodes {
        version
        name
      }
    }
    brands: allDirectusBrands(filter: {id: {ne: "dummy"}}, sort: {fields: brand___modified_on, order: DESC}) {
      group(field: brand___type___type) {
        fieldValue
        nodes {
          brand {
            modified_on
            type {
              type
            }
            brand_rating
            display_name
          }
        }
      }
    }
    brandTypes: allDirectusBrandTypes(filter: {id: {ne: "dummy"}}) {
      nodes {
        type
        translations {
          display_name
          language
        }
      }
    }
  }
`;